.external-link-decorator {
	display: block;
	margin-bottom: rem(24);
	text-align: center;

	&:last-child {
		margin-bottom: 0;
	}

	a {
		display: block;
		padding-top: 60px;
		font-size: rem(20);
		font-weight: 600;
		background: url('../img/download.png') no-repeat center top;
		background-size: auto 50px;
	}
}
